type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;
interface IGaEvent {
  event: string;
  formType: string;
  userId?: string;
}

interface ICalendlyUser {
  userName: string;
  email: string;
}

interface ICalendlyEvent {
  assigned_to?: string;
  event_end_time?: string;
  event_start_time?: string;
  event_type_name?: string;
  event_type_uuid?: string;
  invitee_email?: string;
  invitee_full_name?: string;
  invitee_uuid?: string;
  utm_campaign?: string;
}
interface IHubspotUser {
  userName: string;
  email: string;
  phone: string;
}

const checkDataLayer = () => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
};

const getFirstPartOfEmail = (email: string) => {
  return email.replace(/@.*$/, '');
};

export const getCalendlyUrlWithoutBase = (url: string) => {
  return url.replace(/^https:\/\/calendly\.com\/api\/v2\//, '');
};

export const handleGaEvent = ({ event, formType, userId }: IGaEvent) => {
  checkDataLayer();
  window.dataLayer.push({
    event,
    formType,
    userId,
  });
};

export const addHubspotUserToGA = ({ userName, email, phone }: IHubspotUser) => {
  checkDataLayer();
  const emailFirstPart = getFirstPartOfEmail(email);

  // console.log('Hubspot to Gtm', {
  //   event: 'formSubmission',
  //   formType: 'Request contact',
  //   userName,
  //   phone,
  //   emailFirstPart,
  // });

  window.dataLayer.push({
    event: 'formSubmission',
    formType: 'Request contact',
    userName,
    phone,
    emailFirstPart,
  });
};

export const addCalendlyUserToGA = ({ userName, email }: ICalendlyUser) => {
  checkDataLayer();
  // const emailFirstPart = getFirstPartOfEmail(email);
  // console.log('Calendly to Gtm', {
  //   event: 'Calendly_Scheduled',
  //   userName,
  //   emailFirstPart,
  // });

  window.dataLayer.push({
    event: 'Calendly_Scheduled',
    userName,
    email,
  });
};

export const addCalendlyEventToGA = (eventData: ICalendlyEvent) => {
  checkDataLayer();
  const {
    assigned_to,
    event_start_time,
    event_type_name,
    event_type_uuid,
    invitee_email,
    invitee_full_name,
    invitee_uuid,
    utm_campaign,
  } = eventData;

  window.dataLayer.push({
    event: 'calendly_header_scheduled',
    assigned_to,
    calendly_call_start: event_start_time,
    calendly_event_name: event_type_name,
    calendly_event_uuid: event_type_uuid,
    email: invitee_email,
    userName: invitee_full_name,
    invitee_uuid,
    calendly_utm: utm_campaign,
  });
};

export const scrollIntoViewWithOffset = (selector: string, offset: number) => {
  const elementTop = document?.querySelector(selector)?.getBoundingClientRect()?.top;
  const bodyLenght = document?.body?.getBoundingClientRect()?.top;
  const scrollingLenght = elementTop && bodyLenght ? elementTop - bodyLenght - offset : 0;

  window.scrollTo({
    behavior: 'smooth',
    top: scrollingLenght,
  });
};
